import * as React from "react"
import { Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { FiArrowRight } from "react-icons/fi"

import Paragraph from "../atoms/paragraph"
import Title from "../atoms/title"
import { ServiceItemProps } from "../../interfaces/ServiceItemProps"

const ServiceCard: React.FC<{ data: ServiceItemProps }> = ({ data }) => {
  const icon =
    data.postTypeService.global.icon &&
    getImage(data.postTypeService.global.icon.localFile)
  const thumbnail =
    data.postTypeService.global.thumbnail &&
    getImage(data.postTypeService.global.thumbnail.localFile)
  return (
    <Link
      to={data.uri}
      className="relative bg-center bg-cover group overflow-hidden"
    >
      <div className="relative text-center flex items-center justify-center mx-auto h-96 py-m">
        <div className="z-30">
          {icon && (
            <GatsbyImage
              className="m-auto"
              image={icon}
              alt={data.postTypeService.global.icon.altText}
            />
          )}
          <div className="transform lg:h-0 lg:scale-0 lg:hidden group-hover:h-full group-hover:block group-hover:scale-100 px-m py-s">
            <Title as="h3">{data.title}</Title>
            <Paragraph className="text-xs my-xs">
              {data.hero_section.subtitle}
            </Paragraph>
            <FiArrowRight className="mx-auto text-lg text-primary group-hover:animate-bounce animation" />
          </div>
        </div>
        <div className="z-20 absolute p-s w-full h-full flex items-center justify-center">
          <div className="opacity-90 lg:opacity-100 transition-all bg-white w-full h-full lg:w-20 lg:h-20 lg:rounded-full group-hover:w-full group-hover:h-full group-hover:rounded-none" />
        </div>
        {thumbnail && (
          <div className="absolute top-0 z-10 w-full h-full">
            <GatsbyImage
              style={{ minHeight: "100%" }}
              image={thumbnail}
              alt={data.postTypeService.global.thumbnail.altText}
            />
          </div>
        )}
      </div>
    </Link>
  )
}

export default ServiceCard
