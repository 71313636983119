import * as React from "react"
import { Link } from "gatsby"
import classnames from "classnames"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import SectionTitle from "../molecules/sectionTitle"
import { TeamProps } from "../../interfaces/TeamProps"

const Team: React.FC<TeamProps> = ({ data, className }) => {
  const BgImg = getImage(data.background.localFile)
  if (data) {
    return (
      <div className={classnames("relative pt-l", className)}>
        <div className="relative pt-m z-10 container">
          <SectionTitle {...data.sectionTitle} className="text-center" />
          <div className="pt-l px-m flex items-end justify-center">
            {data.members.map((el, i) => {
              const TeamImg = getImage(el.image.localFile)
              return (
                <div
                  className="relative -mx-s transition-transform transform hover:scale-110 origin-bottom"
                  key={i}
                >
                  {el.linkedin && TeamImg ? (
                    <Link to={"/"}>
                      <GatsbyImage
                        image={TeamImg}
                        alt={el.image.altText || ""}
                      />
                    </Link>
                  ) : TeamImg ? (
                    <GatsbyImage image={TeamImg} alt={el.image.altText || ""} />
                  ) : null}
                </div>
              )
            })}
          </div>
        </div>
        <div className="z-0 bottom-0 absolute w-full">
          {BgImg && <GatsbyImage image={BgImg} alt="" />}
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default Team
