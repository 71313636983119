import * as React from "react"
import classnames from "classnames"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Paragraph from "../atoms/paragraph"
import { HotspotsImageProps } from "../../interfaces/HotspotsImageProps"

const HotspotsImage: React.FC<HotspotsImageProps> = ({ data, className }) => {
  const hotspotImage = data.image && getImage(data.image.localFile)
  if (data) {
    return (
      <div className={classnames("relative", className)}>
        {hotspotImage && (
          <GatsbyImage
            className="w-full"
            image={hotspotImage}
            alt={data.image.altText}
          />
        )}
        {data.services.map((el, i) => {
          const icon =
            el.postTypeService.global.icon &&
            getImage(el.postTypeService.global.icon.localFile)
          if (el.postTypeService.global.position.x) {
            return (
              <div
                className="group absolute"
                style={{
                  top: `${el.postTypeService.global.position.y}%`,
                  left: `${el.postTypeService.global.position.x}%`,
                }}
                key={i}
              >
                <Link to={el.uri} key={i} className="flex">
                  {icon && (
                    <div className="bg-white border-2 border-primary w-8 lg:w-11 h-8 lg:h-11 flex rounded-full opacity-0">
                      <GatsbyImage
                        className="m-auto"
                        style={{ width: 20 }}
                        image={icon}
                        alt={el.postTypeService.global.icon.altText}
                      />
                    </div>
                  )}
                  <div
                    key={i}
                    className="z-20 group-hover:block hidden bg-white rounded-lg p-xxs lg:px-xs lg:py-xxs my-auto"
                  >
                    <Paragraph className="font-bold text-xs">
                      {el.title}
                    </Paragraph>
                  </div>
                </Link>
              </div>
            )
          }
        })}
      </div>
    )
  } else {
    return null
  }
}

export default HotspotsImage
