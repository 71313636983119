import * as React from "react"
import { PageProps, graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"

import Layout from "../layout"
import Hero from "../organisms/hero"
import Advantages from "../organisms/advantages"
import Services from "../organisms/services"
import Portfolio from "../organisms/portfolio"
import Team from "../organisms/team"
import Testimonials from "../organisms/testimonials"
import { ProjectsItemProps } from "../../interfaces/ProjectsItemProps"
import { TestimonialItemProps } from "../../interfaces/TestimonialItemProps"

type DataProps = {
  wpPage: {
    hero_section: any
    seo: any
    homepage: {
      advantages: any[]
      services: any
      portfolio: any
      team: any
      testimonials: any
    }
  }
  allWpService: { nodes: any[] }
}

const Homepage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { hero_section, homepage, seo } = data.wpPage
  const { advantages, services, portfolio, team, testimonials } = homepage

  const allServices = data.allWpService.nodes

  let allPortfolioProject: ProjectsItemProps[] = []
  let allTestimonials: TestimonialItemProps[] = []
  data.allWpService.nodes.map(el => {
    allPortfolioProject.push(el.postTypeService.portfolio?.[0])
    allTestimonials.push(el.postTypeService.testimonials?.[0])
  })

  return (
    <Layout>
      <Seo post={data.wpPage} />
      <Hero data={hero_section} />
      <Advantages data={advantages} />
      <Services
        data={{
          sectionTitle: {
            title: services.title,
            subtitle: services.subtitle,
          },
          image: services.image,
          services: allServices,
        }}
      />
      <Portfolio
        data={{
          sectionTitle: portfolio,
          projects: allPortfolioProject,
        }}
      />

      <Team
        data={{
          sectionTitle: {
            title: team.title,
            subtitle: team.subtitle,
          },
          background: team.background,
          members: team.members,
        }}
      />
      <Testimonials
        data={{
          sectionTitle: testimonials,
          testimonials: allTestimonials,
        }}
      />
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery($id: String) {
    wpPage(id: { eq: $id }) {
      nodeType
      title
      uri
      ...heroPageFields
      ...seoPagesFields
      homepage {
        advantages {
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: TRACED_SVG
                  formats: AUTO
                  pngOptions: { compressionSpeed: 10, quality: 85 }
                  width: 90
                  layout: CONSTRAINED
                )
              }
            }
          }
          title
        }
        services {
          subtitle
          title
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: TRACED_SVG
                  formats: AUTO
                  pngOptions: { compressionSpeed: 10, quality: 85 }
                  jpgOptions: { quality: 85 }
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        portfolio {
          subtitle
          title
        }
        team {
          background {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  formats: AUTO
                  pngOptions: { compressionSpeed: 10, quality: 85 }
                  jpgOptions: { quality: 85 }
                  layout: FULL_WIDTH
                )
              }
            }
          }
          subtitle
          title
          members {
            linkedin
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    formats: AUTO
                    pngOptions: { compressionSpeed: 10, quality: 85 }
                    jpgOptions: { quality: 85 }
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
        testimonials {
          subtitle
          title
        }
      }
    }
    allWpService {
      nodes {
        uri
        title
        hero_section {
          subtitle
        }
        postTypeService {
          global {
            icon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    formats: AUTO
                    pngOptions: { compressionSpeed: 10, quality: 85 }
                    height: 45
                    layout: CONSTRAINED
                  )
                }
              }
            }
            position {
              x
              y
            }
            thumbnail {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    formats: AUTO
                    pngOptions: { compressionSpeed: 10, quality: 85 }
                    jpgOptions: { quality: 85 }
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          portfolio {
            location
            text
            thumbnail {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    formats: AUTO
                    pngOptions: { compressionSpeed: 10, quality: 85 }
                    jpgOptions: { quality: 85 }
                    layout: FULL_WIDTH
                  )
                }
              }
            }
            title
          }
          testimonials {
            name: nom
            testimonial: paragraph
          }
        }
      }
    }
  }
`

export default Homepage
