import * as React from "react"
import classnames from "classnames"

import SectionTitle from "../molecules/sectionTitle"
import HotspotsImage from "../molecules/hotspotsImage"
import ServiceCard from "../molecules/serviceCard"
import { ServicesProps } from "../../interfaces/ServicesProps"

const Services: React.FC<ServicesProps> = ({ data, className }) => {
  if (data) {
    return (
      <div className={classnames("py-m", className)}>
        {data.sectionTitle && (
          <div className="container">
            <SectionTitle {...data.sectionTitle} className="text-center" />
          </div>
        )}
        {data.image && (
          <HotspotsImage
            data={{
              image: data.image,
              services: data.services,
            }}
            className="max-w-6xl mx-auto hidden md:block"
          />
        )}
        {data.services.length > 0 && (
          <div className="container">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {data.services.map((el, i) => (
                <ServiceCard data={el} key={i} />
              ))}
            </div>
          </div>
        )}
      </div>
    )
  } else {
    return null
  }
}

export default Services
