import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import classnames from "classnames"

import Paragraph from "../atoms/paragraph"
import { AdvantagesProps } from "../../interfaces/AdvantagesProps"

const Advantages: React.FC<AdvantagesProps> = ({ data, className }) => {
  if (data) {
    return (
      <div
        className={classnames(
          "grid grid-cols-2 lg:grid-cols-4 text-center w-max	mx-auto mb-l -mt-m shadow-lg relative z-10 bg-white",
          className
        )}
      >
        {data.map((el, i) => {
          const image = getImage(el.image.localFile)
          return (
            <div className="p-xs sm:s md:p-m relative" key={i}>
              {image && (
                <GatsbyImage
                  className="mx-auto mb-s w-12 lg:w-20"
                  image={image}
                  alt={el.image.altText}
                />
              )}
              {el.title && (
                <Paragraph className="uppercase font-semibold">
                  {el.title}
                </Paragraph>
              )}
              {data.length - 1 !== i && (
                <div className="hidden lg:block absolute top-1/2 right-0 h-8 w-px bg-light-gray" />
              )}
            </div>
          )
        })}
      </div>
    )
  } else {
    return null
  }
}

export default Advantages
