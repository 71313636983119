import * as React from "react"
import classnames from "classnames"
import Slider from "react-slick"
import { FiArrowRight, FiArrowLeft } from "react-icons/fi"

import SectionTitle from "../molecules/sectionTitle"
import ProjectCard from "../molecules/projectCard"
import { PortfolioProps } from "../../interfaces/PortfolioProps"

const Portfolio: React.FC<PortfolioProps> = ({ data, className }) => {
  function ArrowLeft(props: any) {
    const { className, onClick } = props
    return (
      <FiArrowRight className={classnames("", className)} onClick={onClick} />
    )
  }
  function ArrowRight(props: any) {
    const { className, onClick } = props
    return (
      <FiArrowLeft className={classnames("", className)} onClick={onClick} />
    )
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    centerMode: true,
    centerPadding: "200px",
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <ArrowLeft />,
    prevArrow: <ArrowRight />,
    responsive: [
      {
        breakpoint: 1660,
        settings: {
          slidesToShow: 3,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
    ],
  }
  if (data.projects.length > 0) {
    return (
      <div
        className={classnames(
          "mt-l pb-xl bg-gradient-to-t via-white from-extra-light-gray to-transparent relative z-10",
          className
        )}
      >
        {data.sectionTitle && (
          <div className={classnames("container")}>
            <SectionTitle {...data.sectionTitle} className="text-center" />
          </div>
        )}
        {data.projects.length > 1 ? (
          <>
            <link
              rel="stylesheet"
              type="text/css"
              charSet="UTF-8"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
            />
            <div className="max-w-full">
              <Slider {...settings}>
                {data.projects.map((el, i) => (
                  <ProjectCard className="m-auto xs:m-s" data={el} key={i} />
                ))}
              </Slider>
            </div>
          </>
        ) : (
          <div className="max-w-full">
            {data.projects.map((el, i) => (
              <ProjectCard className="max-w-xs mx-auto" data={el} key={i} />
            ))}
          </div>
        )}
      </div>
    )
  } else {
    return null
  }
}

export default Portfolio
